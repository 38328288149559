// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,

  // Configuration of content
  appName: 'Helpful Information',
  appLogoUrl: 'https://www.ifrc.org/sites/default/files/media/logo/2021-09/iso2_jo.jpg',
  mainPageHeader: '',
  mainPageIntroduction: '\n \n',
  mainPageNotification: '',
  errorHeader: 'Error - Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'email',
  errorRetry: 'Try again?',

  // Regions:
  regions: 'ar, en',
  regionsLabels: 'العربية, English',
  regionsSheetIds: '1HnP3Khi3uQMGCXQPQhfLonye0UPpU0CBNuj0n6EMrxQ, 1wT6wwf1WN75blGuIMKM3z-1bmVTXBWj9bKxH_iq0PFg',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyBTytPRa8AixaodZKJ8fJhmTrSI0v0rjj8',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: '',
};
